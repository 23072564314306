$main-color: #1F243C;
$text-color: #FBFBFC;
$subtext-color: #929CC0;
$footer-color: #98A2C6;
$hover-text-color: #97A4C0;
$border: #2B304C;

$btn-bg: #5CB8E7;
$btn-bg-click: #53a3cd;
$max-width: 1280px;

$header-bg: #2D324E;
$header-color: #929CC0;

$pricing-bg: #2E3560;
$pricing-bg-hover: #293D71;
$pricing-features-bg: #5377F6;

$input-bg: #6980A4;

$card-bg: #272D4E;
$card-bg-hover: #2E3560;

$loading-text-color: #98A2C6;

$message-background-error-color: #b3452e;
$message-background-success-color: #299e3f;

$max-width: 1280px;
