.menu {
  display: flex;
  flex: 1;
  margin: 0 40px;
  align-items: center;

  a {
    display: flex;
    align-items: center;
  }

  ul {
    margin: 0 20px;
    display: flex;
    flex: 1;
    justify-content: space-around;
  }
}

.menuElement {
  display: flex;
  flex: 1;
}

.downloadLink {

}

@media (min-width: 320px) and (max-width: 480px) {
  .menuElement, .downloadLink {
    display: none;
  }
}
