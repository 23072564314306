@import '../../styles/theme';
@import '../../styles/mixins';

.wrapper {
  @include centerFlexGrow();
}

.loader {
  align-items: center;
  color: $loading-text-color;
  display: flex;
  flex-direction: column;
  flex: 1;
  font-size: 24px;
  font-weight: bold;
  height: 100%;
  justify-content: center;
  opacity: 0.8;
  text-align: center;

  .animation {
    span {
      animation: pulse 0.4s alternate infinite ease-in-out;
      display: inline-block;
      padding: 0 8px;
      &:nth-child(odd) {
        animation-delay: 0.4s;
      }
    }

    .insideText {
      font-size: 14px;
      padding-top: 3px;
    }
  }

  .noAnimation {
    display: inline-block;
    padding: 0 8px;

    .insideText {
      font-size: 14px;
      height: 36px;
      padding: 8px 0px;
    }
  }
}

.braces {
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: center;
}

.counter {
  margin-top: 12px;
}

@keyframes pulse {
  to {
    opacity: 0.5;
    transform: scale(0.8);
  }
}
