@import "./theme";
@import "./mixins";

@import url('https://fonts.googleapis.com/css2?family=Poppins&family=Arimo&display=swap');

body, html {
  width: 100%;
  height: 100%;
}

* {
  margin: 0;
  padding: 0;
}

body {
  background: $main-color;
  color: $text-color;
  font-family: 'Poppins', 'Arimo', sans-serif;
}

h1 {
  font-style: normal;
  font-weight: 600;
  font-size: 56px;
  line-height: 84px;
  text-align: center;

  color: $text-color;
}

h3 {
  font-size: 18px;
  font-weight: 400;
}

h4 {
  background: $header-bg;
  color: $header-color;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  padding: 12px 32px;
}

a {
  color: $text-color;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  text-decoration: none;
}

a:hover {
  color: $hover-text-color
}

button {
  align-items: flex-start;
  background: $btn-bg;
  border-radius: 4px;
  border: 1px solid $btn-bg;
  color: $text-color;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-weight: 700;
  justify-content: center;
  padding: 12px 40px;

  &.bordered {
    background: none;
    &:active {
      background-color: rgba(0,0,0,.1);
    }
  }

  &:hover {
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  }

  &:active {
    background-color: $btn-bg-click;
  }

  &[disabled] {
    opacity: 0.4;
    cursor: not-allowed;
  }
}

ul {
  display: flex;
  flex-wrap: wrap;

  li {
    list-style: none;
  }
}

input, textarea, select {
  font-family: 'Poppins', 'Arimo', sans-serif;
  background: $main-color;
  border: 1px solid $input-bg;
  box-sizing: border-box;
  border-radius: 4px;
  color: $text-color;
  font-weight: lighter;
  font-size: 16px;
  line-height: 24px;
  padding: 8px 16px;
  width: 100%;
}

select {
  -moz-appearance:none; /* Firefox */
  -webkit-appearance:none; /* Safari and Chrome */
  appearance:none;
}

select.minimal {
  background-image:
          linear-gradient(45deg, transparent 50%, $input-bg 50%),
          linear-gradient(135deg, $input-bg 50%, transparent 50%),
          linear-gradient(to right, $input-bg, $input-bg);
  background-position:
          calc(100% - 20px) calc(1em + 2px),
          calc(100% - 15px) calc(1em + 2px),
          calc(100% - 2.5em) 0.5em;
  background-size:
          5px 5px,
          5px 5px,
          1px 1.5em;
  background-repeat: no-repeat;
}

select.minimal:focus {
  background-image:
          linear-gradient(45deg, $btn-bg 50%, transparent 50%),
          linear-gradient(135deg, transparent 50%, $btn-bg 50%),
          linear-gradient(to right, $btn-bg, $btn-bg);
  background-position:
          calc(100% - 15px) 1em,
          calc(100% - 20px) 1em,
          calc(100% - 2.5em) 0.5em;
  background-size:
          5px 5px,
          5px 5px,
          1px 1.5em;
  background-repeat: no-repeat;
  border-color: $btn-bg;
  outline: 0;
}

header {
  align-items: center;
  display: flex;
  flex-direction: unset;
  height: 82px;
  min-height: 82px;
  width: 100%;
  justify-content: space-between;
}

main {
  @include fullFillFlex();
  align-items: center;
  flex: 1;
  height: initial;
  margin: 0 auto;
  overflow: hidden;
  position: relative;
}

footer {
  width: 100%;
}

#root {
  @include fullHeightWidth();
}

.bm-burger-button {
  display: none;
}

.bm-burger-bars {
  background: $text-color;
}

.bm-burger-bars-hover {
  background: $hover-text-color;
}

.bm-cross-button {
  height: 24px;
  width: 24px;
}

.bm-cross {
  background: $text-color;
}

.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

.bm-menu {
  background: $card-bg;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

.bm-morph-shape {
  fill: $card-bg;
}

.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
}

.bm-item {
  display: inline-block;
  margin-bottom: 12px;
  width: 100%;

  button {
    width: 100%;
    margin-bottom: 24px;
  }
}

.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

@media (min-width: 320px) and (max-width: 480px) {
  h1 {
    font-size: 32px;
    line-height: 38px;
  }

  h3 {
    font-size: 16px;
    line-height: 24px;
  }

  .bm-burger-button {
    display: block;
    position: fixed;
    width: 36px;
    height: 30px;
    right: 36px;
    top: 26px;
  }
}
