@mixin fullHeightWidth() {
  height: 100%;
  width: 100%;
}

@mixin fullFillAbsolute() {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

@mixin fullFillFlex() {
  @include fullHeightWidth();
  @include flexColumn();
}

@mixin flexGrow() {
  display: flex;
  flex: 1;
}

@mixin centerFlex() {
  align-items: center;
  display: flex;
  justify-content: center;
}

@mixin centerFlexGrow() {
  align-items: center;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
}

@mixin flexColumn() {
  display: flex;
  flex-direction: column;
}

@mixin flexCenterAndColumn() {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@mixin flexRow() {
  display: flex;
  flex-direction: row;
}

@mixin centerAbsolute() {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}

@mixin fillWidth() {
  display: block;
  width: 100%;
}

@mixin centerAndSpaceBetween() {
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.hidden {
  display: none;
}
