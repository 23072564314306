@import "../../../../styles/theme";

.wrapper {
  align-items: center;
  border-top: 1px solid $border;
  color: $footer-color;
  display: flex;
  flex-direction: row;
  font-family: 'Arimo';
  font-size: 16px;
  font-style: normal;
  font-weight: normal;
  justify-content: space-around;
  line-height: 24px;
  padding: 32px;

  span {
    opacity: 0.5;
  }

  ul {
    margin-left: 18px;

    li {
      border-left: 1px solid $border;
      padding: 0 18px;
      opacity: 0.5;

      &:hover {
        opacity: 1;
      }
      a {
        color: $footer-color;
        &:hover {
          color: $hover-text-color;
        }
      }
    }
  }
}

.left {
  display: flex;
  flex-direction: row;
}

.right {
  a {
    padding-left: 32px;
    &:hover {
      svg {
        path {
          fill: $btn-bg;
        }
      }
    }
  }
}
