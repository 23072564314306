@import "../../styles/theme";
@import "../../styles/mixins";

.app {
  @include fullHeightWidth();

  display: flex;
  flex-direction: column;
  height: initial;
  justify-content: space-between;
  min-height: 100%;
}

.menuWrapper {
  justify-content: center;
  margin: 0 auto;
  position: sticky;
  top: 0;
  z-index: 100;
  backdrop-filter: blur(4px);
  background: rgba(0,0,0,.25);
  box-shadow: 0 6px 16px rgba(0, 0, 0, .4);

  > div {
    max-width: $max-width;
  }
}
